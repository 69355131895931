import { axiosPrivate } from "../axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import { authSelector, authTokenSelector, logout } from "../features/authSlice"
import { useSelector, useDispatch } from "react-redux"
import setAuthToken from "../utils/setAuthToken";
const useAxiosPrivate = () => {
    const auth = useSelector(authSelector)
    useEffect(() => {
        async function fetchData() {
            const here = localStorage.getItem("token")
            await setAuthToken(here)
        }
        fetchData();
    }, [auth])
    return axiosPrivate;
}

export default useAxiosPrivate;