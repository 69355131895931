import React from "react";
import Switch from "@mui/material/Switch";

function SwitchButton(props) {
  const { defaultChecked, disabled, onChange } = props;
  return (
    <div>
      <Switch defaultChecked={defaultChecked} disabled={disabled} onChange={onChange} />
    </div>
  );
}

export default SwitchButton;
