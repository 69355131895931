import React, { useEffect, useState } from "react";
import * as sideMenus from "../utils/sideMenus";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import TableauGrid from "../components/TableauGrid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { authSelector } from "../features/authSlice";
import Controls from "../components/controls/Controls";
import Loading from "../components/Loading";
import DialogBox from "../components/DialogBox";
import { useFOrm } from '../components/useFOrm'
import * as bannerService from '../utils/bannderService.js'
import { listUserSelector, setallUsers } from '../features/listUserSlice';
import { toast } from "react-toastify";

const initialFvalues = {
    label: "",
}
const GroupScreen = (props) => {
    const { t } = props;
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [groupList, setGroupList] = useState();
    const [activitiesList, setActivitiesList] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('label' in fieldValues)
            temp.label = fieldValues.label ? "" : t("error.required")


        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "");
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        resetForm,
        handleInputChange
    } = useFOrm(initialFvalues, true, validate)

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getGroups = async () => {
            try {
                const { data } = await axiosPrivate.get(`/api/group/getgroups`, {
                    signal: controller.signal,
                });
                //console.log("datad", data.filter(f => f.type == null).sort((a, b) => a.label !== b.label ? a.label < b.label ? -1 : 1 : 0))
                const sortdata = data.filter(f => f.type == null).sort((a, b) => a.label !== b.label ? a.label < b.label ? -1 : 1 : 0)
                const sortdataActivities = data.filter(f => f.type == "p").sort((a, b) => a.label !== b.label ? a.label < b.label ? -1 : 1 : 0)
                isMounted && setGroupList(sortdata);
                isMounted && setActivitiesList(sortdataActivities);
            } catch (error) {
                toast.error('An error occurred while getting the list of groups')
                //console.log(error)
                //navigate('/login', { state: { from: location }, replace: true })
                // if (error.response.status != 403) {
                //   navigate("/login", { state: { from: location }, replace: true });
                // }
            }
        };
        //getReportingUsers();
        getGroups()
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);


    function refreshPage() {
        window.location.reload(false);
    }


    const openModalAddGroup = async () => {
        try {
            initialFvalues.label = ''
            setIsOpen(!isOpen)
        } catch (error) {

        }
    }

    const openModalAddActivity = async () => {
        try {
            initialFvalues.label = ''
            setIsOpen3(!isOpen3)
        } catch (error) {

        }
    }

    const addGroup = async () => {
        try {

            if (validate()) {
                console.log("values", values)

                const newGroup = {
                    label: values.label,
                    type: null
                }

                const { data } = await axiosPrivate.post(`/apiv1/bo/addGroup`, newGroup);
                refreshPage()
            }


        } catch (error) {
            console.log("sggd", error)
        }
    }

    const updateGroup = async (id) => {
        try {

            if (validate()) {
                console.log("values", values)
                const updateData = {
                    label: values.label
                }
                const { data } = await axiosPrivate.put(`/apiv1/bo/editGroup/${id}`, updateData);
                refreshPage()
            }


        } catch (error) {
            console.log("sggd", error)
        }
    }

    const deleteGroup = async (id) => {
        try {
            await axiosPrivate.delete(`/apiv1/bo/removeGroup/${id}`);
            refreshPage()
        } catch (error) {

        }
    }


    const addActivity = async () => {
        try {

            if (validate()) {
                console.log("values", values)
                console.log("values", values)

                const newGroup = {
                    label: values.label,
                    type: 'p'
                }

                const { data } = await axiosPrivate.post(`/apiv1/bo/addGroup`, newGroup);
                refreshPage()
            }
        } catch (error) {

        }
    }


    const columns = [


        {
            field: "label",
            headerName: 'Group name',
            width: 150,

        },

        {
            field: "createdBy",
            headerName: 'Created By',
            width: 150,
        },
        {
            field: "action",
            headerName: '',
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-primary dark:text-slate-100 hover:text-secondary"}
                        text={"Edit"}
                        onClick={(event) => {
                            const dataToShow = groupList.filter((a) => a.id === cellValues.row.id);
                            console.log("dasdadasdasd", dataToShow)
                            /* initialFvalues.memberid = dataToShow[0]?.member?.id || 0
                             initialFvalues.url = dataToShow[0]?.url
                             initialFvalues.screenName = dataToShow[0]?.screenName
                             initialFvalues.pageUrl = dataToShow[0].pageUrl || null
                             initialFvalues.level = dataToShow[0]?.level
                             initialFvalues.state = dataToShow[0].state ? 1 : 0*/
                            initialFvalues.label = dataToShow[0]?.label
                            setSelectedRow(dataToShow[0]);
                            setIsOpen2(true);
                        }}
                    />
                );
            },
        },

        {
            field: "action2",
            headerName: '',
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-red-500 dark:text-slate-100 hover:text-secondary"}
                        text={"Delete"}
                        onClick={async (event) => {
                            await deleteGroup(cellValues.row.id)
                        }}
                    />
                );
            },
        },
    ];

    const columnsActivity = [


        {
            field: "label",
            headerName: 'Group name',
            width: 150,

        },

        {
            field: "createdBy",
            headerName: 'Created By',
            width: 150,
        },
        {
            field: "action",
            headerName: '',
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-primary dark:text-slate-100 hover:text-secondary"}
                        text={"Edit"}
                        onClick={(event) => {
                            const dataToShow = activitiesList.filter((a) => a.id === cellValues.row.id);
                            initialFvalues.label = dataToShow[0]?.label
                            setSelectedRow(dataToShow[0]);
                            setIsOpen4(true);
                        }}
                    />
                );
            },
        },

        {
            field: "action2",
            headerName: '',
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-red-500 dark:text-slate-100 hover:text-secondary"}
                        text={"Delete"}
                        onClick={async (event) => {
                            await deleteGroup(cellValues.row.id)
                        }}
                    />
                );
            },
        },
    ];

    return (
        <>
            <TopBar menus={sideMenus.menusAdmin(t)} />
            <div className="flex flex-row flex-grow">
                <Sidebar menus={sideMenus.menusAdmin(t)} />

                <div className="flex flex-col md:flex-row flex-1 mt-2 md:ml-52 ">
                    {/* Group */}
                    <div className="flex flex-col w-full p-4 ">
                        <div className="flex-none h-auto">
                            <h1 className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.groupList`)}</h1>

                            <hr />
                        </div>
                        <div className='flex justify-start mb-3'>
                            <Controls.Button small xsFont text="New Group" onClick={openModalAddGroup} />
                        </div>

                        <DialogBox
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            title="New Group"
                            customClassName={"md:w-1/2 w-full min-h-[300px]"}
                        >
                            <div className="h-fit bg-slate-100 my-2 p-2">
                                <div className='flex flex-col gap-y-5'>

                                    <Controls.Input
                                        fullWidth={true}
                                        label="Group Name"
                                        value={values.label || ""}
                                        onChange={handleInputChange}
                                        name="label"
                                        error={errors.label}
                                    />

                                    <div className='flex justify-end w-full space-x-3'>
                                        <Controls.Button small xsFont color={'bg-slate-300 text-black'} text="Cancel" onClick={() => setIsOpen(false)} />
                                        <Controls.Button small xsFont color={'bg-green-500 text-white'} text="Save" onClick={addGroup} />
                                    </div>
                                </div>
                            </div>
                        </DialogBox>



                        <div>
                            <div className="h-[76vh] bg-slate-50">
                                {groupList && groupList ? <TableauGrid idRows={'label'} column={columns} dataRows={groupList && groupList} showQuickFilter /> : <Loading />}
                            </div>

                            <DialogBox
                                isOpen={isOpen2}
                                setIsOpen={setIsOpen2}
                                title={'Edit group'}
                                customClassName={"md:w-1/2 w-full min-h-[300px]"}
                            >

                                <div className="h-fit bg-slate-100 my-2 p-2">
                                    {selectedRow && selectedRow ?
                                        <>
                                            <div className="h-fit bg-slate-100 my-2 p-2">
                                                <div className='flex flex-col gap-y-5'>

                                                    <Controls.Input
                                                        fullWidth={true}
                                                        label="Group Name"
                                                        value={values.label || ""}
                                                        onChange={handleInputChange}
                                                        name="label"
                                                        error={errors.label}
                                                    />

                                                    <div className='flex justify-end w-full space-x-3'>
                                                        <Controls.Button small xsFont color={'bg-slate-300 text-black'} text="Cancel" onClick={() => setIsOpen(false)} />
                                                        <Controls.Button small xsFont color={'bg-yellow-300 text-black'} text="Update" onClick={() => updateGroup(selectedRow.id)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                        : null
                                    }
                                </div>
                            </DialogBox>
                        </div>
                    </div>
                    {/* groupActivity */}
                    <div className="flex flex-col w-full p-4 ">
                        <div className="flex-none h-auto">
                            <h1 className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.activitiesList`)}</h1>

                            <hr />
                        </div>

                        <div className='flex justify-start mb-3'>
                            <Controls.Button small xsFont text="New Activity" onClick={openModalAddActivity} />
                        </div>

                        <DialogBox
                            isOpen={isOpen3}
                            setIsOpen={setIsOpen3}
                            title="New partner activity"
                            customClassName={"md:w-1/2 w-full min-h-[300px]"}
                        >

                            <div className="h-fit bg-slate-100 my-2 p-2">
                                <div className='flex flex-col gap-y-5'>

                                    <Controls.Input
                                        fullWidth={true}
                                        label="Activity name"
                                        value={values.label || ""}
                                        onChange={handleInputChange}
                                        name="label"
                                        error={errors.label}
                                    />

                                    <div className='flex justify-end w-full space-x-3'>
                                        <Controls.Button small xsFont color={'bg-slate-300 text-black'} text="Cancel" onClick={() => setIsOpen3(false)} />
                                        <Controls.Button small xsFont color={'bg-indigo-500 text-white'} text="Save activity" onClick={addActivity} />
                                    </div>
                                </div>
                            </div>
                        </DialogBox>



                        <div>
                            <div className="h-[76vh] bg-slate-50">
                                {activitiesList && activitiesList ? <TableauGrid idRows={'label'} column={columnsActivity} dataRows={activitiesList && activitiesList} showQuickFilter /> : <Loading />}
                            </div>

                            <DialogBox
                                isOpen={isOpen4}
                                setIsOpen={setIsOpen4}
                                title={'Edit partner activity'}
                                customClassName={"md:w-1/2 w-full min-h-[300px]"}
                            >

                                <div className="h-fit bg-red-100 my-2 p-2">
                                    {selectedRow && selectedRow ?
                                        <>
                                            <div className="h-fit bg-slate-100 my-2 p-2">
                                                <div className='flex flex-col gap-y-5'>

                                                    <Controls.Input
                                                        fullWidth={true}
                                                        label="Activity Name"
                                                        value={values.label || ""}
                                                        onChange={handleInputChange}
                                                        name="label"
                                                        error={errors.label}
                                                    />

                                                    <div className='flex justify-end w-full space-x-3'>
                                                        <Controls.Button small xsFont color={'bg-slate-300 text-black'} text="Cancel" onClick={() => setIsOpen(false)} />
                                                        <Controls.Button small xsFont color={'bg-yellow-300 text-black'} text="Update" onClick={() => updateGroup(selectedRow.id)} />
                                                    </div>
                                                </div>
                                            </div>

                                        </>

                                        : null
                                    }
                                </div>
                            </DialogBox>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GroupScreen