import React, { useEffect, useState } from "react";
import * as sideMenus from "../utils/sideMenus";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import TableauGrid from "../components/TableauGrid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { authSelector } from "../features/authSlice";
import Controls from "../components/controls/Controls";
import Loading from "../components/Loading";
import DialogBox from "../components/DialogBox";
import { useFOrm } from '../components/useFOrm'
import * as bannerService from '../utils/bannderService.js'
import { listUserSelector, setallUsers } from '../features/listUserSlice';
import { toast } from "react-toastify";
const initialFvalues = {
    memberid: "",
    url: "",
    screenName: "",
    pageUrl: "",
    level: "",
    state: ""
}


function BannerScreen(props) {
    const { t } = props;
    const axiosPrivate = useAxiosPrivate();
    let [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const location = useLocation();
    const auth = useSelector(authSelector);
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = useState();
    const [bannerList, setBannerList] = useState();
    const listU = useSelector(listUserSelector)
    const dispacth = useDispatch();
    const [users, setUsers] = useState();
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('memberid' in fieldValues)
            temp.memberid = fieldValues.memberid ? "" : t("error.required")
        if ('url' in fieldValues)
            temp.url = fieldValues.url ? "" : t("error.required")
        if ('screenName' in fieldValues)
            temp.screenName = fieldValues.screenName ? "" : t("error.required")
        if ('level' in fieldValues)
            temp.level = fieldValues.level ? "" : t("error.required")
        if ('state' in fieldValues)
            temp.state = String(fieldValues.state) != "" ? "" : t("error.required")

        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "");
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        resetForm,
        handleInputChange
    } = useFOrm(initialFvalues, true, validate)


    function refreshPage() {
        window.location.reload(false);
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getAllBanner = async () => {
            try {
                const resp = await axiosPrivate.get(`/api/banner/getbanner`, {
                    signal: controller.signal,
                });

                console.log("++++", resp.data)
                isMounted && setBannerList(resp?.data?.sort((a, b) => b.id - a.id));
            } catch (error) {

                if (error.response.status != 403) {
                    navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };

        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get(`/apiv1/bo/getAllMember`, {
                    signal: controller.signal,
                });
                isMounted && setUsers(response.data);

                if (response.data) {
                    dispacth(setallUsers(response.data.filter(d => (d.type === 'Partener Gold' || d.type === 'Partener')).sort((a, b) => b.id - a.id)));
                }
            } catch (error) {
                if (error.response.status != 403) {
                    navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };

        getAllBanner()

        getUsers()

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);



    const openModalAdd = async () => {
        try {
            initialFvalues.memberid = ""
            initialFvalues.url = ""
            initialFvalues.screenName = ""
            initialFvalues.pageUrl = ""
            initialFvalues.level = ""
            initialFvalues.state = ""
            setIsOpen2(!isOpen2)
        } catch (error) {

        }
    }


    const addBanner = async () => {
        if (validate()) {
            //update user data
            try {
                if (values.pageUrl == '') {
                    values.pageUrl = null
                }
                const newList = await axiosPrivate.post(`/api/banner/createBanner`, values);
                console.log("ewdsfsdfdsfrew", values)
                setBannerList(newList?.data?.sort((a, b) => b.id - a.id));
                setIsOpen2(false)

                toast.success('Banner added')
            } catch (error) {
                toast.error('Error while added banner')
            }

        }
    }

    const updateBanner = async (id) => {
        if (validate()) {
            //update user data
            try {
                if (values.pageUrl == '') {
                    values.pageUrl = null
                }
                const newList = await axiosPrivate.put(`/api/banner/updateBanner/${id}`, values);
                console.log("ewdsfsdfdsfrew", values)
                setBannerList(newList?.data?.sort((a, b) => b.id - a.id));
                setIsOpen(false)
                refreshPage()
                toast.success('Banner updated')
            } catch (error) {
                toast.error('Error in updated banner')
            }

        }
    }



    const deletBanner = async (id) => {
        try {
            await axiosPrivate.delete(`/api/banner/deleteBanner/${id}`);
            //setBannerList(respAfterDelete?.data?.sort((a, b) => b.id - a.id));
            //setBannerList(respAfterDelete.data);
            refreshPage()
            setIsOpen(false)
            toast.success('Banner deleted')
        } catch (error) {
            console.log("erer", error)
        }
    }

    const columns = [
        {
            field: "id",
            headerName: "id",
            hide: true,
        },
        {
            field: "createdAt",
            headerName: "Created date",
            type: "date",
            width: 150,
            renderCell: (cellValues) => {
                if (cellValues.row.createdAt) {
                    return moment(cellValues.row.createdAt).format("DD/MM/YYYY HH:mm");
                } else {
                    return '-';
                }
            },
        },
        {
            field: "member",
            headerName: 'Partener username',
            width: 150,
            renderCell: (cellValues) => {
                if (cellValues.row.member) {
                    return cellValues.row.member.pseudo
                } else {
                    return '-';
                }
            },
        },

        {
            field: "member2",
            headerName: 'Partner email',
            width: 150,
            renderCell: (cellValues) => {
                if (cellValues.row.member) {
                    return cellValues.row.member.email
                } else {
                    return '-';
                }
            },
        },

        {
            field: "screenName",
            headerName: 'Screen name',
            width: 150,
            renderCell: (cellValues) => {
                if (cellValues.row.screenName) {
                    return t(`${cellValues.row.screenName}`)
                } else {
                    return '-';
                }
            },
        },

        {
            field: "url",
            headerName: 'Miniature',
            width: 150,
            renderCell: (cellValues) => {
                if (cellValues.row.url) {
                    return (
                        <img alt="no url" className='object-contain h-8 mx-auto' src={cellValues.row.url} />
                    )
                } else {
                    return null;
                }
            },
        },



        {
            field: "pageUrl",
            headerName: 'Page Url ',
            width: 150,
            renderCell: (cellValues) => {
                if (cellValues.row.pageUrl) {
                    // return t(`${cellValues.row.screenName}`)
                    return <a href={cellValues.row.pageUrl} target="_blank">{cellValues.row.pageUrl}</a>
                } else {
                    return '-';
                }
            },
        },

        {
            field: "state",
            headerName: 'Status',
            width: 150,
            renderCell: (cellValues) => {

                return cellValues.row.state ? "Visible" : "Hidden"

            },
        },



        {
            field: "action",
            headerName: '',
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-primary dark:text-slate-100 hover:text-secondary"}
                        text={"Edit"}
                        onClick={(event) => {
                            const dataToShow = bannerList.filter((a) => a.id === cellValues.row.id);

                            console.log("dasdadasdasd", dataToShow)

                            initialFvalues.memberid = dataToShow[0]?.member?.id || 0
                            initialFvalues.url = dataToShow[0]?.url
                            initialFvalues.screenName = dataToShow[0]?.screenName
                            initialFvalues.pageUrl = dataToShow[0].pageUrl || null
                            initialFvalues.level = dataToShow[0]?.level
                            initialFvalues.state = dataToShow[0].state ? 1 : 0
                            setSelectedRow(dataToShow[0]);
                            setIsOpen(true);
                        }}
                    />
                );
            },
        },

        {
            field: "action2",
            headerName: '',
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-red-500 dark:text-slate-100 hover:text-secondary"}
                        text={"Delete"}
                        onClick={async (event) => {
                            await deletBanner(cellValues.row.id)
                        }}
                    />
                );
            },
        },
    ];
    return (
        <>
            <TopBar menus={sideMenus.menusAdmin(t)} />
            <div className="flex flex-row flex-grow">
                <Sidebar menus={sideMenus.menusAdmin(t)} />

                <div className="flex flex-row flex-1 mt-2 md:ml-52 ">
                    <div className="flex flex-col w-full p-4 ">
                        <div className="flex-none h-auto">
                            <h1 className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.banner`)}</h1>
                            <div className='text-xs text-slate-500'>
                                Banner size should be 300px by 128px
                            </div>
                            <hr />
                        </div>

                        <div className='flex justify-end mb-3'>
                            <Controls.Button small xsFont text="Add new Banner" onClick={openModalAdd} />
                        </div>

                        <DialogBox
                            isOpen={isOpen2}
                            setIsOpen={setIsOpen2}
                            title="New banner"
                            customClassName={"md:w-1/2 w-full min-h-[300px]"}
                        >
                            <div className="h-fit bg-slate-100 my-2 p-2">

                                <div className='flex flex-col gap-y-5'>

                                    <div className='text-xs'>
                                        Please upload your image on cloudinary
                                        <a href="https://cloudinary.com/users/login"> https://cloudinary.com/users/login</a>

                                        <div>
                                            Copy the Url after uploading the new banner and paste it in the Url field
                                        </div>
                                    </div>

                                    <Controls.Select
                                        name="memberid"
                                        label={'Select Partner'}
                                        value={values.memberid}
                                        onChange={handleInputChange}
                                        options={listU.map(l => {
                                            return {
                                                id: l.id,
                                                title: `${l.pseudo} - ${l.email}`
                                            }
                                        })}
                                        error={errors.memberid}
                                    />


                                    <div className='flex items-center justify-center h-32 space-x-5'>

                                        <Controls.Input
                                            fullWidth={true}
                                            label="Banner url "
                                            value={values.url || ""}
                                            onChange={handleInputChange}
                                            name="url"
                                            error={errors.url}
                                            help={"Url from upload"}
                                        />


                                        <img className='object-contain h-32' src={values.url} />

                                    </div>

                                    {/* <Controls.Input
                                        fullWidth={true}
                                        label="Banner url "
                                        value={values.url || ""}
                                        onChange={handleInputChange}
                                        name="url"
                                        error={errors.url}
                                        help={"Url from upload"}
                                    /> */}

                                    <Controls.Input
                                        fullWidth={true}
                                        label="On click Banner url"
                                        value={values.pageUrl || ""}
                                        onChange={handleInputChange}
                                        name="pageUrl"
                                        help={"If banner is pressed the app will be redirected to this url"}
                                    />

                                    <Controls.Select
                                        name="screenName"
                                        label={'Screen name'}
                                        value={values.screenName || ""}
                                        onChange={handleInputChange}
                                        options={bannerService.getScreenNameCollection()}
                                        error={errors.screenName}
                                        help={"where the banner appears on the app"}
                                    />

                                    <Controls.Select
                                        name="level"
                                        label={'Level'}
                                        value={values.level}
                                        onChange={handleInputChange}
                                        options={bannerService.getlevelCollection()}
                                        error={errors.level}
                                    />

                                    <Controls.Select
                                        name="state"
                                        label={'Status'}
                                        value={values.state}
                                        onChange={handleInputChange}
                                        options={bannerService.getStatusCollection()}
                                        error={errors.state}
                                    />

                                    <div className='flex justify-end w-full space-x-3'>
                                        <Controls.Button small xsFont color={'bg-slate-300 text-black'} text="Cancel" onClick={() => setIsOpen(false)} />
                                        <Controls.Button small xsFont color={'bg-green-500 text-white'} text="Save" onClick={addBanner} />
                                    </div>
                                </div>




                            </div>

                        </DialogBox>



                        <div>
                            <div className="h-[76vh] bg-slate-50">
                                {bannerList && bannerList ? <TableauGrid column={columns} dataRows={bannerList && bannerList} showQuickFilter /> : <Loading />}
                            </div>
                            <DialogBox
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                title={'Banner details'}
                                customClassName={"md:w-1/2 w-full min-h-[300px]"}
                            >

                                <div className="h-fit bg-slate-100 my-2 p-2">
                                    {selectedRow && selectedRow ?
                                        <>
                                            <div className='flex flex-col gap-y-5'>

                                                {/* <div className='flex  space-x-2 items-center  text-xs '>
                                                    <div className='font-semibold'>Partner: </div>
                                                    <div>
                                                        {selectedRow?.member?.pseudo} - {selectedRow?.member?.email}
                                                    </div>

                                                </div> */}



                                                <Controls.Select
                                                    name="memberid"
                                                    label={'Select Partner'}
                                                    value={values.memberid || ""}
                                                    onChange={handleInputChange}
                                                    options={listU && listU.map(l => {
                                                        return {
                                                            id: l.id,
                                                            title: `${l.pseudo} - ${l.email}`
                                                        }
                                                    })}
                                                    error={errors.memberid}
                                                />



                                                <div className='flex items-center justify-center h-32 space-x-5'>

                                                    <Controls.Input
                                                        fullWidth={true}
                                                        label="Banner url "
                                                        value={values.url || ""}
                                                        onChange={handleInputChange}
                                                        name="url"
                                                        error={errors.url}
                                                        help={"Url from upload"}
                                                    />


                                                    <img className='object-contain h-32' src={values.url} />

                                                </div>

                                                <Controls.Input
                                                    fullWidth={true}
                                                    label="On click Banner url"
                                                    value={values.pageUrl || ""}
                                                    onChange={handleInputChange}
                                                    name="pageUrl"
                                                    error={errors.pageUrl}
                                                    help={"If banner is pressed the app will be redirected to this url"}
                                                />

                                                <Controls.Select
                                                    name="level"
                                                    label={'Level'}
                                                    value={values.level}
                                                    onChange={handleInputChange}
                                                    options={bannerService.getlevelCollection()}
                                                    error={errors.screenName}
                                                />

                                                <Controls.Select
                                                    name="state"
                                                    label={'Status'}
                                                    value={values.state}
                                                    onChange={handleInputChange}
                                                    options={bannerService.getStatusCollection()}
                                                    error={errors.state}
                                                />


                                                <div className='flex space-x-2 items-center  text-xs '>
                                                    Created : {moment(selectedRow.createdAt).format("DD/MM/YYYY")}
                                                </div>

                                                <div className='flex space-x-2 items-center  text-xs text-red-500 '>
                                                    Please note that the delete action is not reversible
                                                </div>

                                                <div className='flex justify-end w-full space-x-3'>
                                                    <Controls.Button small xsFont color={'bg-slate-300 text-black'} text="Cancel" onClick={() => setIsOpen(false)} />
                                                    <Controls.Button small xsFont color={'bg-red-500 text-white'} text="Delete" onClick={() => deletBanner(selectedRow.id)} />
                                                    <Controls.Button small xsFont color={'bg-indigo-500 text-white'} text="Update" onClick={() => updateBanner(selectedRow.id)} />
                                                </div>

                                            </div>
                                        </>

                                        : null
                                    }
                                </div>
                            </DialogBox>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BannerScreen;
