import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authSelector } from "../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import moment from "moment";
import Users from "../components/Users";
import TopBar from '../components/TopBar';
import Sidebar from '../components/Sidebar';
import * as sideMenus from "../utils/sideMenus";
function CpScreen(props) {
  const { t } = props;
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(authSelector);
  const [users, setUsers] = useState();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getReportingUsers = async () => {
      try {
        const { data } = await axiosPrivate.get(`/apiv1/bo/getAllMember`, {
          signal: controller.signal,
        });

        console.log("===============", data)
        //isMounted && setUsers(response.data);
      } catch (error) {
        //console.log(error)
        //navigate('/login', { state: { from: location }, replace: true })
        // if (error.response.status != 403) {
        //   navigate("/login", { state: { from: location }, replace: true });
        // }
      }
    };
    //getReportingUsers();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <>
      <TopBar menus={sideMenus.menusAdmin(t)} />
      {/* <div className="flex h-full ">
        <div className=" flex-1 h-[89vh] p-4">
          <div className="flex-none h-auto mt-2 px-4">
            <h1 className="font-thin text-2xl text-slate-700 dark:text-white">
              Hello, <span className="font-semibold">{auth.name}</span>{" "}
            </h1>
            <span className="text-xs text-slate-500 dark:text-slate-400 ">{moment().format("Do MMMM YYYY")}</span>
          </div>

          <div className="flex-auto space-y-4 mt-4 p-4  h-[500px] overflow-y-auto  bg-slate-100 dark:bg-slate-800">
            <Users t={t} />
          </div>
        </div>
      </div> */}

      <div className="flex flex-row flex-grow">
        <Sidebar menus={sideMenus.menusAdmin(t)} />
        <div className="flex flex-row flex-1 mt-2 md:ml-52 ">
          <div className="flex flex-col w-full p-4 ">
            <div className="flex-none h-auto">
              <h1 className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.membre`)}</h1>
              <hr />
            </div>

            <div>
              <div className="h-[76vh] bg-slate-50">
                <Users t={t} />
              </div>

            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default CpScreen;
