import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { themeSelector } from "../features/themeSlice"

export default function useDarkMode() {
    const themeData = useSelector(themeSelector)
    const [theme, setTheme] = useState(themeData)
    const colorTheme = theme === 'dark' ? 'light' : 'dark';
    useEffect(() => {
        const root = window.document.documentElement;
        root.classList.remove(colorTheme)
        root.classList.add(theme)
    }, [theme, colorTheme])

    return [colorTheme, setTheme]
} 