import React from 'react'
import FileBase64 from "react-file-base64"
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material'
import { Button } from '@mui/material'
export default function FileBase(props) {
    const { multiple, onDone, error = null, label, name, onChange } = props
    const convertToDefaultEventPara = (name, value) => ({
        target: {
            name,
            value: value
        }
    })
    return (
        <FormControl variant="outlined" sx={{ width: "100%" }}
            {...(error && { error: true })}
        >
            <InputLabel>{label}</InputLabel>
            <Button variant="contained" component="label" color="secondary">
                Choisissez un fichier
                <FileBase64
                    name={name}
                    multiple={true}
                    onDone={onDone}

                />

            </Button>
            {error && <FormHelperText >{error}</FormHelperText>}
        </FormControl>

    )
}
