import { createSlice } from "@reduxjs/toolkit";

const initialState = "light"

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        updateTheme: (state, action) => (action.payload)
    }
});

export const { updateTheme } = themeSlice.actions
export const themeSelector = (state) => state.theme;

export default themeSlice.reducer
