import React from "react";
import LoginForm from "./LoginForm";
import logoMeet from "../../asset/img/Meet-and-fun-logo.png";

function Login(props) {
	const { t } = props;
	return (
		<div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
			<div className="max-w-md w-full space-y-3">
				<div>
					<img
						className="object-contains h-32 rounded-full mx-auto"
						src={logoMeet}
					/>
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-slate-100">
						{t('login')}
					</h2>
				</div>
				<LoginForm t={t} />
			</div>
		</div>
	);
}

export default Login;
