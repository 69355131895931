export const getScreenNameCollection = () => (
    [
        { id: 'about', title: "About you" },
        { id: 'events', title: "Events list" },
        { id: 'groupList', title: "Group List" },
        { id: 'register', title: "Join Community" },
        { id: 'location', title: "Location & Language" },
        { id: 'conversation', title: "Message / chat" },
        { id: 'mypost', title: "My post" },
        { id: 'myphoto', title: "My photo" },
        { id: 'myvideo', title: "My Video" },
        { id: 'mystat', title: "My stat" },
        { id: 'actus', title: "News feed" },
        { id: 'notification', title: "Notification" },
        { id: 'partnerList', title: "Partner list" },
        { id: 'infoperso', title: "Personal Info" },
        { id: 'profilepic', title: "Profile picture" },
        { id: 'search', title: "Search" },
        { id: 'topHome', title: "Top Home" },
        { id: 'bottomHome', title: "Bottom Home" },
        { id: 'topProfile', title: "Top profile" },
        { id: 'bottomProfile', title: "Bottom profile" }

    ]
)

export const getlevelCollection = () => (
    [
        { id: 1, title: "Level 1" },
        { id: 2, title: "Level 2" },
        { id: 3, title: "Level 3" }
    ]
)

export const getStatusCollection = () => (
    [
        { id: 1, title: "Visible" },
        { id: 0, title: "Hidden" }

    ]
)