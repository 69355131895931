import axios from "axios"
axios.defaults.withCredentials = true
export default axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin":"*"
    },
    proxy: {
        host: process.env.REACT_APP_BASE_URL
    }
});


export const axiosPrivate = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin":"*"
    
    },
    proxy: {
        host: process.env.REACT_APP_BASE_URL
    }
})