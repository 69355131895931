import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { authSelector } from "../features/authSlice"
import { useSelector } from "react-redux"

const RequireAuth = ({ allowedRole }) => {
    const auth = useSelector(authSelector);
    const location = useLocation();
    return (
        auth?.role?.find(r => allowedRole?.includes(r))
            ? < Outlet />
            : auth?.token
                ? <Navigate to='/unauthorized' state={{ form: location }} replace />
                : <Navigate to='/login' state={{ form: location }} replace />
    )
}

export default RequireAuth
