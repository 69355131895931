import { createSlice } from "@reduxjs/toolkit";
import axiosGlobal from "../axios";
import { toast } from "react-toastify";

const initialState = {
  email: "",
  role: [],
  token: "",
  name: "",
  id: 0,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.token = action.payload.token;
      state.role = action.payload.role.split(",");
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.id = action.payload.id;
    },
    logout: (state, action) => {
      return initialState;
    },
    updateToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { login, logout, updateToken } = authSlice.actions;
export const authSelector = (state) => state.auth;
export const authTokenSelector = (state) => state.auth?.token;

export default authSlice.reducer;
