import React, { useState } from "react";
import { useFOrm, Form } from "../useFOrm";
import Controls from "../controls/Controls";
import { Grid } from "@mui/material";
import { login } from "../../features/authSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import axiosGlobal from "../../axios";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
const initialFvalues = {
	email: "",
	password: "",
};

function LoginForm(props) {
	const location = useLocation();
	const from = location.state?.from?.pathname || "/";
	const cookies = new Cookies();
	const { t } = props;
	const dispacth = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const validate = (fieldValues = values) => {
		let temp = { ...errors };
		if ("email" in fieldValues) temp.email = fieldValues.email ? "" : t("error.required")
		if ("password" in fieldValues) temp.password = fieldValues.password ? "" : t("error.required");
		setErrors({
			...temp,
		});

		if (fieldValues == values) return Object.values(temp).every((x) => x == "");
	};

	const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(
		initialFvalues,
		true,
		validate
	);

	const formLogin = [
		{
			type: "email",
			name: "email",
			label: t("label.username"),
			placeholder: "Enter your email",
			password: false,
			required: true,
		},
		{
			type: "password",
			name: "password",
			label: t("label.password"),
			placeholder: "******",
			password: true,
			required: true,
		},
	];

	let navigate = useNavigate();
	const handleSubmit = async (e) => {
		console.log("ato")
		e.preventDefault();
		if (validate()) {
			setIsLoading(true)
			const data = {
				email: values.email,
				password: values.password,
			};
			try {
				const response = await axiosGlobal.post("/apiv1/bo/login", data);
				if (response) {
					const dataLogin = {
						token: '',
						role: response.data.superadmin.role,
						email: response.data.superadmin.email,
						name: response.data.superadmin.name,
						id: response.data.superadmin.id
					};

					console.log("test ", dataLogin)
					//const cryptToken = services.encryptCB(response.data.token)
					localStorage.setItem("token", response.data.token)

					//cookies.set('refreshToken', response.data.refreshToken, { path: '/' });
					dispacth(login(dataLogin));
					const rolesArray = dataLogin.role.split(",");
					switch (true) {
						case rolesArray.includes("0"):
							navigate("/hello")
							break;
						default:
							navigate(from, { replace: true });
							break;
					}

					setIsLoading(false)

				}

			} catch (error) {
				toast.error('Email ou Mot de passe incorrect')
				setIsLoading(false)
			}
		} else {
		}
	};

	return (
		<Form onSubmit={handleSubmit} noValidate>
			<Grid container spacing={2}>
				{formLogin.map((item, i) => {
					return (
						<Grid item xs={12} key={i}>
							<Controls.Input
								fullWidth={true}
								label={item.label}
								value={values[item.name]}
								onChange={handleInputChange}
								name={item.name}
								type={item.type}
								error={errors[item.name]}
							/>
						</Grid>
					);
				})}
			</Grid>
			<div style={{ marginTop: "20px" }}>
				{/* <Controls.Button type="submit" text={t('button.login')} fullWidth /> */}
				<Controls.Button type="submit" text={t("button.login")} widthFull isLoading={isLoading} />
			</div>
		</Form>
	);
}

export default LoginForm;
