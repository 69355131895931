import React from "react";
import Button from "./Button";
import Checkbox from "./Checkbox";
import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import FileBase from "./FileBase";
import RangePicker from "./RangePicker";
import AutoComplete from "./AutoComplete";
import SwitchButton from "./SwitchButton";
const Controls = {
  Button,
  Input,
  Checkbox,
  RadioGroup,
  Select,
  FileBase,
  RangePicker,
  AutoComplete,
  SwitchButton,
};

export default Controls;
