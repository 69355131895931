import React, { useState } from 'react'

export function useFOrm(initialFvalues, validateOnChange = false, validate) {

    const [values, setValues] = useState(initialFvalues);
    const [errors, setErrors] = useState({});
    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })

        if (validateOnChange) {
            validate({ [name]: value })
        }

        console.log("values" , values)
    }

    const resetForm = () => {
        setValues(initialFvalues)
        setErrors({})
    }

    return {
        values,
        setValues,
        errors,
        setErrors,
        resetForm,
        handleInputChange
    }
}


export function Form(props) {

    const { children, ...other } = props;
    return (
        <form autoComplete='off' {...other} >{props.children}</form>
    )
}

