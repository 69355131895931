import { createSlice, current } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = []

export const listUserSlice = createSlice({
    name: 'listUser',
    initialState,
    reducers: {
        setallUsers: (state, action) => {
            return action.payload;
        }
    }
});

export const { setallUsers } = listUserSlice.actions
export const listUserSelector = (state) => state.listUser;
export default listUserSlice.reducer
