import React, { useState, useEffect } from "react";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { DataGrid, frFR, GridToolbar } from "@mui/x-data-grid";
import Controls from "../controls/Controls";
import { useFOrm } from "../useFOrm";
//Slices
import { setallUsers, listUserSelector } from "../../features/listUserSlice";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "../DialogBox";
import { XIcon, CheckIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import moment from "moment";
import Loading from "../Loading";
import { authSelector } from "../../features/authSlice";
import TableauGrid from '../TableauGrid';
import Switch from '@mui/material/Switch';
import { toast } from "react-toastify";
const initialFvalues = {
  isActived: ''
};


function Users(props) {
  const { t, fetchAllUser, reset } = props;
  const [users, setUsers] = useState();
  const listUser = useSelector(listUserSelector);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const dispacth = useDispatch();
  const [isFiltered, setIsFiltered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dataSteps, setDataSteps] = useState(false);
  const [emailSelected, setEmailSelected] = useState("");
  const [userselected, setUserSelected] = useState();
  const [checked, setChecked] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let test = values.trigger || null;

    setErrors({
      ...temp,
    });
    console.log("test qq", temp);
    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(
    initialFvalues,
    true,
    validate
  );

  const MyIndicator = ({ value }) => {
    if (!value) return <XCircleIcon className="w-4 h-4 text-red-500" />;
    return <CheckCircleIcon className="w-4 h-4 text-green-500" />;
  };

  const auth = useSelector(authSelector);
  const listU = useSelector(listUserSelector)


  const columnSuperAdmin = [
    { field: "id", headerName: "ID", type: "number", headerClassName: "dark:text-slate-100", hide: true },

    {
      field: "pseudo",
      width: 150, headerName: t("label.username"), headerClassName: "dark:text-slate-100"
    },
    {
      field: "lastName",
      width: 150,
      headerName: t("label.lastName"),
      headerClassName: "dark:text-slate-100",
    },
    {
      field: "firstName",
      width: 150,
      headerName: t("label.firstName"),
      headerClassName: "dark:text-slate-100",
    },
    {
      field: "email",
      width: 200,
      headerName: t("label.email"),
      headerClassName: "dark:text-slate-100",
    },
    {
      field: "type",
      width: 150,
      headerName: t("label.type"),
      headerClassName: "dark:text-slate-100",
      renderCell: (cellValues) => {
        return (
          cellValues.row.type == 'Partener' ? "Partner" : cellValues.row.type == 'Partener Gold' ? "Partner Gold" : null
        )
      },
    },


    {
      field: "isActived",
      width: 150,
      headerName: t("label.isActived"),
      headerClassName: "dark:text-slate-100",
      renderCell: (cellValues) => {
        return (
          cellValues.row.isActived ? <CheckIcon className='h-5 w-5 text-green-500 mx-auto' /> : <XIcon className='h-5 w-5 text-red-500 mx-auto' />
        )
      },
    },



    {
      field: "action",
      headerName: "",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {

        return (

          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={t("button.edit")}
            onClick={(event) => {
              const dataToShow = users.filter((a) => a.id === cellValues.row.id);
              setUserSelected(dataToShow[0]);
              setIsOpen(true);
            }}
          />
        );
      },
    },


    {
      field: "action2",
      headerName: "",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (

          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-red-500 dark:text-slate-100 hover:text-secondary"}
            text={t("button.delete")}
            onClick={(event) => {
              const dataToShow = users.filter((a) => a.id === cellValues.row.id);
              setSelectedRow(dataToShow[0]);
              setIsOpenConfirm(!isOpenConfirm)
              //setIsOpen(true);
            }}
          />
        );
      },
    },


  ];

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        // const response = await axiosPrivate.get(`/api/users/genericFindAll`, {
        //     signal: controller.signal
        // });
        const response = await axiosPrivate.get(`/apiv1/bo/getAllMember`, {
          signal: controller.signal,
        });
        isMounted && setUsers(response.data);

        console.log("USER", response.data);
        if (response.data) {
          // dispacth(setallUsers(response.data.filter(d => (d.type === 'Partener Gold' || d.type === 'Partener')).sort((a, b) => b.id - a.id)));
          dispacth(setallUsers(response.data));
        }
      } catch (error) {
        //console.log(error)

        if (error.response.status != 403) {
          navigate("/login", { state: { from: location }, replace: true });
        } /*
                setTimeout(() => {

                }, 5000)*/
      }
    };

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const SimpleRender = ({ label, text }) => (
    <div className='flex text-sm space-x-2'>
      <div>{label}: </div>
      <div>{text}</div>
    </div>
  )

  const handleValidate = async () => {

    if (validate()) {
      const dataToUpdate = {
        isActived: checked
      }
      console.log("data", dataToUpdate)
      const response = await axiosPrivate.put(`/apiv1/bo/updateMember/${userselected.id}`, dataToUpdate);
      dispacth(setallUsers(response.data.sort((a, b) => b.id - a.id)));
      setIsOpen(!isOpen);
      // open 1st modal are you sure
    }
  };


  useEffect(() => {
    if (userselected) {
      setChecked(userselected.isActived)
    }
  }, [userselected])

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleDeleteAccount = async (id) => {
    try {
      setIsLoading(true)
      const respAfterDeleteMember = await axiosPrivate.delete(`/apiv1/bo/deleteAccount/${id}`);

      console.log(id)
      dispacth(setallUsers(listUser.filter(l => l.id != id)));
      setIsLoading(false)
      //setIsOpen(false)
      setIsOpenConfirm(false)
      //refreshPage()
      toast.success("Member account deleted")

    } catch (error) {
      console.log("err", error)
      setIsLoading(false)
      toast.error("Error while deleting acccount")
    }

  }


  return (
    <>

      <DialogBox
        isOpen={isOpenConfirm}
        setIsOpen={setIsOpenConfirm}
        title={'Are you sure ?'}
        customClassName={"md:w-1/2 w-full min-h-[10px]"}
      >
        <div className='flex flex-col items-center justify-center space-y-4 text-sm'>
          <div className='text-lg'>
            If you delete <span className='text-green-600 text-lg font-bold'> {selectedRow?.pseudo} </span> account this membre he/she will no have access to the app anymore</div>
          <div>
            <span className='text-red-500 font-semibold text-lg'>This action is not reversible</span>
          </div>

          <div className='flex justify-center w-full space-x-3'>
            <Controls.Button color={'bg-slate-300 text-black'} text="Cancel" onClick={() => setIsOpenConfirm(false)} isLoading={isLoading} />
            <Controls.Button color={'bg-red-500 text-white'} text="Continue" onClick={() => handleDeleteAccount(selectedRow.id)} isLoading={isLoading} />
          </div>

        </div>

      </DialogBox>
      <DialogBox isOpen={isOpen} setIsOpen={setIsOpen} title={userselected && userselected.pseudo}>
        <div className="flex flex-col gap-y-2">
          <SimpleRender label={t('email')} text={userselected && userselected.email} />
          <div className='flex items-center'>
            <div>Active</div>

            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
          <Controls.Button text="Confirmer" onClick={handleValidate} />
        </div>
      </DialogBox>

      {users && users ? <TableauGrid column={columnSuperAdmin} dataRows={listU && listU} showQuickFilter /> : <Loading />}
    </>
  );
}

export default Users;
