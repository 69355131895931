import React, { useEffect, useState } from "react";
import { MoonIcon, SunIcon, LogoutIcon, LoginIcon, UserCircleIcon, CogIcon } from "@heroicons/react/outline";
import useDarkMode from "../../hooks/useDarkMode";
import logoMeet from "../../asset/img/Meet-and-fun-logo.png";
import { authSelector, logout } from "../../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MyPopover from "../MyPopover";


function Header(props) {
  const { t } = props;
  const [colorTheme, setColorTheme] = useDarkMode();
  const dispacth = useDispatch();
  const auth = useSelector(authSelector);
  //const [isOpen, setOpen] = useOpen()
  const [isOpen, setIsOpen] = useState();
  const navigate = useNavigate();
  const Hello = () => {
    navigate("/login");
  };
  const goodBye = () => {
    setIsOpen(false);
    dispacth(logout());
    navigate("/login");
  };

  const CustomMenu = ({ icon, title, onClick }) => (
    <div className="flex text-sm items-center p-2 hover:bg-slate-200 hover:cursor-pointer gap-x-2" onClick={onClick}>
      {icon}
      {title}
    </div>
  );

  return (
    <>
      <div
        className={`z-10 shadow-sm flex  ${auth.email ? "justify-between" : "justify-center"}   items-center p-2  bg-white dark:bg-slate-800 sticky top-0 w-full`}
      >
        <div className={`flex gap-x-4 items-center `}>
          <img
            className="object-contains h-8 rounded-full"
            src={logoMeet}
          />
        </div>



        {/* </Link> */}
        {/* <MyPopover isOpen={isOpen} setIsOpen={setIsOpen} onMouseEnter={() => setIsOpen(false)}>
          <div className="bg-white flex-col shadow-xl border-r-2 border-r-cyan-500 ">
            <CustomMenu
              icon={<LogoutIcon className="w-5 h-5 dark:text-slate-200 " />}
              title={"LogOut"}
              onClick={goodBye}
            />
          </div>
        </MyPopover> */}

        {/* <div>
                <ViewListIcon className='h-5 w-5 block md:hidden' onClick={() => { dispacth(updateShow('show')) }} />
            </div> */}
        <div className=" flex justify-end space-x-3 items-center">

          <CustomMenu
            icon={<LogoutIcon className="w-5 h-5 dark:text-slate-200 " />}
            title={"Logout"}
            onClick={goodBye}
          />
          {/* <div className='rounded-full p-1 hover:cursor-pointer ' onClick={() => { setColorTheme(colorTheme); dispacth(updateTheme(colorTheme)) }}>
          {colorTheme === 'dark' ? (
            <MoonIcon className='h-5 w-5 text-indigo-600' />
          ) : (
            <SunIcon className='h-5 w-5 text-indigo-600' />
          )}

        </div> */}

          {/* {auth.email ? (
            <>
              <div className={`text-xs font-semibold text-slate-700`}>{auth.name}</div>
             

              <UserCircleIcon
                className="w-5 h-5 hover:cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
                onMouseEnter={() => setIsOpen(true)}
              />
            </>
          ) : (
            <>
             
            </>
          )} */}
        </div>
      </div>

    </>
  );
}

export default Header;
