import React from 'react'
import { FormControl, FormControlLabel, RadioGroup as MuiRadioGroup, FormLabel, Radio, FormHelperText } from '@mui/material'

export default function RadioGroup(props) {
    const { name, label, value, onChange, items, disabled, readOnly, error = null } = props

    return (
        <FormControl error={error ? true : false} >

            <div className='font-semibold text-sm'>{label}</div>
            <MuiRadioGroup row
                readOnly={readOnly}
                name={name}
                value={value}
                onChange={onChange}>
                {
                    items.map(
                        (item) => (
                            <FormControlLabel key={item.id} value={item.id} disabled={disabled} control={<Radio />} label={item.title} />
                        )
                    )
                }

            </MuiRadioGroup>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
