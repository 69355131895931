import React from "react";
import Login from "../components/Login";

function LoginScreen(props) {
    const { t } = props
    return (
        <>
            <Login t={t} />
        </>
    );
}

export default LoginScreen;