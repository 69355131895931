import React from "react";
import { DataGrid, frFR, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
//import "moment/locale/en";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate, useLocation } from "react-router-dom";

function TableauGrid(props) {
  const { column, dataRows, idRows, showQuickFilter, isOnboarding, hasDate } = props;
  const navigate = useNavigate();

  function NoRowsOverlay() {
    return <div className="h-full flex items-center justify-center">No result.</div>;
  }

  return (
    <>
      <DataGrid
        rows={dataRows ? dataRows : []}
        components={{
          Toolbar: GridToolbar,
          LoadingOverlay: LinearProgress,
          NoRowsOverlay,
        }}
        getRowId={(row) => (row.id ? row.id : row.date ? row.date : idRows)}
        componentsProps={{
          toolbar: {
            showQuickFilter: showQuickFilter,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        //localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          sorting: {
            sortModel: [{ field: `${hasDate ? "date" : "id"}`, sort: "desc" }],
          },
          pinnedColumns: { left: ["id"] },
        }}
        columns={column}
        disableColumnSelector
        disableDensitySelector
        disableColumnFilter
        autoPageSize
        density="compact"
        disableSelectionOnClick={true}
        onRowClick={(rowData) =>
          isOnboarding
            ? navigate(`/onboarding-details/${rowData.row.id}`, {
              state: {
                type: rowData.row.type,
                start: rowData.row.start,
                end: rowData.row.end,
              },
            })
            : null
        }
      //getRowClassName={(params) => `text-slate-800 dark:text-slate-100`}
      // getCellClassName={(params) => `text-slate-800 dark:text-slate-100`}
      />
    </>
  );
}

export default TableauGrid;
