import React from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function AutoComplete(props) {
    const { options, label, onChange, onInputChange, value } = props
    return (
        <Autocomplete
            disablePortal
            autoHighlight
            autoComplete
            onInputChange={onInputChange}
            value={value}
            size="small"
            options={options}
            onChange={onChange}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label={label} />}
        />
    )
}

export default AutoComplete