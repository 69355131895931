import React from 'react'
import image1 from "../asset/img/screen1.png";
import S2 from "../asset/img/screen2.png";
function CguScreen() {
    return (
        <div className="container mx-auto px-4 bg-slate-100 min-h-screen space-y-5 py-4  md:px-52 ">
            <h3 className="text-center font-bold md:text-2xl">
                Conditions Générales d'Utilisation de l'application mobile "MEET AND FUN "
            </h3>
            <div>
                <div className="space-y-2">
                    <h1 className="font-bold text-sm md:text-lg">
                        ARTICLE 1 – OBJET DES CONDITIONS GENERALES D'UTILISATION
                    </h1>
                    <p className="text-justify  text-xs md:text-sm">
                        Les présentes conditions générales d'utilisation (ci-après « CGU ») ont pour objet de définir les
                        règles d'utilisation de l'application mobile «MEET AND FUN » (ci- après « l'Application ») éditée
                        par la société « auto entreprise Vincent JOYEROT nom commerciale MEET and FUN », ayant son siège
                        social 31 rue les amaryllis 13270 Fos sur mer FRANCE <br />
                        En installant l'Application sur votre terminal et/ou en accédant à l'Application, vous acceptez sans
                        réserve l'intégralité des présentes CGU et vous engagez à respecter les obligations à votre charge.
                        Dans le cas où vous n'accepteriez pas les CGU ou auriez des réserves, merci de ne pas utiliser
                        l'Application. Les CGU expriment l'intégralité de l'accord entre vous et MEET AND FUN applicable à
                        votre utilisation de l'Application. MEET AND FUN se réserve le droit de modifier ces CGU en les
                        mettant à jour à tout moment. La version des CGU qui est applicable entre vous et MEET AND FUN est
                        celle en vigueur au moment de votre connexion et de votre utilisation de l'Application. Nous vous
                        conseillons donc de consulter cette page régulièrement afin de prendre connaissance des CGU en
                        vigueur lorsque vous utilisez l'Application.

                    </p>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <h1 className="font-bold text-sm md:text-lg">
                        ARTICLE 2 – CREATION ET REALISATION APPLICATION MOBILE ET SITE WEB
                    </h1>
                    <p className="text-justify  text-xs md:text-sm">
                        Les développements et les codes informatiques sont réalisés par la société Solal and Co Ltd dont le
                        siège social est domicilié Business Corner, Chemin 20 pieds, Grand Baie, Ile Maurice. Site web :
                        https://solal-digital-mauritius.com
                    </p>
                </div>

            </div>


            <div>
                <div className="space-y-2">
                    <h1 className="font-bold text-sm md:text-lg">
                        ARTICLE 3 - HEBERGEMENT
                    </h1>
                    <p className="text-justify  text-xs md:text-sm">
                        Les données sont hébergées chez :<br />

                        Société OVH <br />
                        Siège social : 2 rue Kellermann - 59100 Roubaix, France <br />
                        Tél. +33 (0)8 99 70 17 61 <br />
                        www.ovh.com

                    </p>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <h1 className="font-bold text-sm md:text-lg">
                        ARTICLE 4 – OBJET ET FONCTIONNALITES DE L'APPLICATION
                    </h1>
                    <p className="text-justify  text-xs md:text-sm">
                        L'Application vous permet de : <br />Trouver instantanément les utilisateurs MEET AND FUN autour de vous
                        de Choisir vos critères et de contacter chaque utilisateur de l'application MEET AND FUN depuis
                        l'application ;<br /> Accéder à toute l'actualité de la communauté MEET AND FUN.<br /> Ces fonctionnalités
                        peuvent être enrichies ou modifiées à tout moment par MEET AND FUN sans que sa responsabilité ne
                        puisse être engagée à ce titre.
                    </p>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <h1 className="font-bold text-sm md:text-lg">
                        ARTICLE 5 - ACCES A L'APPLICATION
                    </h1>
                    <p className="text-justify  text-xs md:text-sm">
                        Pour accéder à l'Application, vous devez posséder un terminal mobile et un accès au réseau Internet.
                        L'Application est téléchargeable gratuitement depuis les plateformes « Apple Store » et « Google
                        Play Store » sur les terminaux mobiles disposant d'une version de système d'exploitation permettant
                        son utilisation-. La version du logiciel de l'Application peut être mise à jour de temps à autre
                        pour ajouter de nouvelles fonctions et de nouveaux services. Une fois l'Application installée sur
                        votre terminal, il vous suffit de vous connecter en utilisant votre adresse email et votre mot de
                        passe.
                        Vous vous engagez, lors de votre utilisation et navigation sur l'Application, à fournir des
                        informations vraies, exactes et aussi complètes que possible. Vous vous engagez également à mettre à
                        jour ces informations dans les meilleurs délais en cas de changement de celles-ci.

                    </p>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <h1 className="font-bold text-sm md:text-lg">
                        ARTICLE 6 – MODERATION DES CONTENUS ET COMPORTEMENT ABUSIFS
                    </h1>
                    <p className="text-justify  text-xs md:text-sm">
                        MEET AND FUN contrôle les contenus abusifs de plusieurs manières : <br />
                        <img
                            className="object-contains h-32 mx-auto"
                            src={image1}
                        />
                        • Chaque utilisateur de l’Application peut dénoncer un contenu abusif pour tout type de contenu : Texte, Image, Vidéo ; <br />
                        • MEET AND FUN est notifié de chaque contenu abusif et prend les mesures adéquates : Suppression des contenus et suppression du compte membre ; <br />
                        • MEET AND FUN contrôle régulièrement les contenus publiés sur l’Application et supprime compte membre et contenus jugés abusifs ; <br />
                        • Par ailleurs, chaque membre peut bloquer un autre membre si ce dernier ne souhaite pas être contacté. Depuis le Chat appuyer sur les points et bloquer le membre indésirable ;<br />
                        <img
                            className="object-contains h-32 mx-auto"
                            src={S2}
                        />
                    </p>


                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <h1 className="font-bold text-sm md:text-lg">
                        ARTICLE 7 – LICENCE D'UTILISATION
                    </h1>
                    <p className="text-justify  text-xs md:text-sm">
                        MEET AND FUN vous concède un droit personnel d'utilisation de l'Application et de son contenu. Ce
                        droit est accordé à titre non exclusif, révocable, non cessible, non transférable, mondial et
                        gratuit et pour la seule utilisation de l'Application, à l'exclusion de toute autre finalité. Il est
                        strictement interdit d'accéder et/ou d'utiliser et/ou de tenter d'accéder ou d'utiliser les codes
                        sources ou objets de l'Application.
                        Vous n'acquérez aucun droit de propriété intellectuelle sur l'Application ou son contenu, ni aucun
                        autre droit que ceux conférés par les présentes CGU.
                    </p>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <h1 className="font-bold text-sm md:text-lg">
                        ARTICLE 8 - DONNÉES PERSONNELLES
                    </h1>
                    <p className="text-justify  text-xs md:text-sm">
                        Les données personnelles utilisées sont principalement, adresse mail.
                    </p>
                </div>

            </div>


            <div>
                <div className="space-y-2">
                    <h1 className="font-bold text-sm md:text-lg">
                        ARTICLE 9 - PROPRIÉTÉ INTELLECTUELLE
                    </h1>
                    <p className="text-justify  text-xs md:text-sm">
                        L'ensemble du contenu de l'Application relève de la législation Mauricienne, communautaire et
                        internationale sur le droit d'auteur et la propriété intellectuelle. Tous les droits de reproduction
                        et de représentation afférents à l'Application sont réservés par MEET AND FUN, y compris toutes
                        représentations graphiques, iconographiques et photographiques, ce quel que soit le territoire de
                        protection et que ces droits aient fait l'objet d'un dépôt ou non. La reproduction et/ou
                        représentation de tout ou partie de l'Application, quel que soit le support, en ce compris tous noms
                        commerciaux, marques, logos, noms de domaine et autres signes distinctifs, est formellement
                        interdite et constituerait une contrefaçon sanctionnée par le code de la propriété intellectuelle.
                        Les mots MEET AND FUN et tous les logos sont des marques déposées par MEET AND FUN.
                    </p>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <h1 className="font-bold text-sm md:text-lg">
                        ARTICLE 10 – DISPONIBILITE DE L'APPLICATION
                    </h1>
                    <p className="text-justify  text-xs md:text-sm">
                        L'Application est accessible en ligne 7 jours sur 7 et 24 heures sur 24. Toutefois, compte tenu de
                        la complexité du réseau internet et de l'afflux, à certaines heures, des utilisateurs d'internet,
                        MEET AND FUN n'offre aucune garantie de continuité du service et ne pourra voir sa responsabilité
                        engagée en cas d'impossibilité temporaire d'accéder en tout ou partie à l'Application. Aucun droit à
                        indemnisation n'est accordé au titre du présent alinéa. MEET AND FUN se réserve le droit de cesser,
                        sans préavis, sans indemnité et à son entière discrétion, à titre définitif ou provisoire, de
                        fournir tout ou partie du Service ou des fonctionnalités du Site.
                    </p>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <h1 className="font-bold text-sm md:text-lg">
                        ARTICLE 11 - LIMITATION DE RESPONSABILITE – ABSENCE DE GARANTIE
                    </h1>
                    <p className="text-justify  text-xs md:text-sm">
                        MEET AND FUN s'engage à mettre en œuvre tous les moyens nécessaires afin d'assurer au mieux l'accès
                        à l'Application. Vous êtes seul responsable de votre identifiant. Vous vous engagez à les garder
                        confidentiels et à ne pas les transmettre à des tiers. Si vous avez connaissance d'une utilisation
                        non autorisée de votre compte, il vous appartient d'en informer immédiatement MEET AND FUN en
                        écrivant à l'adresse suivante : <a
                            href="mailto:meetandfunmauritius@gmail.com">meetandfunmauritius@gmail.com</a>
                        De manière générale, vous acceptez et reconnaissez que votre utilisation de l'Application, y compris
                        les informations que vous diffusez, est faite sous votre unique et entière responsabilité. En
                        utilisant l'Application, vous vous engagez à ne pas agir de manière dommageable ou ayant pour effet
                        de causer un préjudice à l'image, aux intérêts ou aux droits de MEET AND FUN, d'endommager ou de
                        rendre inopérante l'Application. MEET AND FUN ne saurait être tenue pour responsable et ne saurait
                        être tenue de vous indemniser du préjudice direct ou indirect qui résulterait de l'indisponibilité
                        de l'Application. MEET AND FUN ne saurait à cet égard être tenue pour responsable de tout dommage
                        résultant de la perte, de l'altération ou de toute utilisation frauduleuse de données, de la
                        transmission accidentelle de virus ou autres éléments nuisibles, de l'attitude ou comportement d'un
                        tiers. Elle n'encourt aucune responsabilité du fait de l'impossibilité d'accéder à l'Application
                        d'un mauvais usage de l'Application de la saturation du réseau internet, d'éventuels
                        dysfonctionnements sur les terminaux mobiles utilisés par vous, en cas de force majeure ou de fait
                        indépendant de sa volonté.
                    </p>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <h1 className="font-bold text-sm md:text-lg">
                        ARTICLE 12 - NON RENONCIATION
                    </h1>
                    <p className="text-justify  text-xs md:text-sm">
                        Le fait pour l'une des parties de ne pas se prévaloir d'un manquement par l'autre partie à l'une
                        quelconque des obligations visées dans les présentes CGU ne saurait être interprété pour l'avenir
                        comme une renonciation à l'obligation en cause.
                    </p>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <h1 className="font-bold text-sm md:text-lg">
                        ARTICLE 13 - DROIT APPLICABLE -LITIGES
                    </h1>
                    <p className="text-justify  text-xs md:text-sm">
                        Les présentes CGU sont soumises au droit français. Tout litige concernant l'Application ou
                        l'interprétation des présentes CGU sera soumis au tribunal compétent.
                    </p>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <h1 className="font-bold text-sm md:text-lg">
                        ARTICLE 14 - CONTACT
                    </h1>
                    <p className=" text-xs md:text-sm">
                        Vous pouvez adresser vos questions ou remarques relatives aux présentes CGU à MEET AND FUN en
                        écrivant à l'adresse suivante: 
                        <a href="mailto:meetandfunmauritius@gmail.com"> meetandfunmauritius@gmail.com</a>
                    </p>
                </div>

            </div>


            <div className="text-[10px] text-center">
                MEET AND FUN
            </div>

        </div>
    )
}

export default CguScreen