import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";

function DialogBox(props) {
  const { children, title, isOpen, setIsOpen, customClassName } = props;
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`min-w-[300px] ${customClassName} transform overflow-hidden rounded-xl bg-white dark:bg-slate-800 p-2 text-left align-middle shadow-xl transition-all`}
              >
                <div className="flex justify-end w-full px-2 absolute right-1">
                  <div
                    onClick={closeModal}
                    className="w-6 h-6 hover:bg-slate-200 hover:cursor-pointer rounded-full p-1"
                  >
                    <XIcon />
                  </div>
                </div>
                <Dialog.Title as="h4" className="text-md font-semibold leading-6 text-slate-800 dark:text-slate-100">
                  {title}
                </Dialog.Title>
                <div className="mt-2">{children}</div>
                {/*
                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="inline-flex
                                        justify-center
                                        rounded-md
                                        border border-transparent
                                        bg-blue-100 px-4 py-2 text-sm
                                        font-medium text-blue-900
                                        hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={closeModal}
                                    >
                                        Got it, thanks!
                                    </button>
                                </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default DialogBox;
