import {
  ViewGridIcon,
  UserGroupIcon,
  CreditCardIcon,
  SwitchHorizontalIcon,
  ViewBoardsIcon,
  ScaleIcon,
  ArrowNarrowLeftIcon,
  PresentationChartLineIcon,
  ShieldExclamationIcon,
  FlagIcon,
  CubeIcon
} from "@heroicons/react/solid";

export const menusAdmin = (t) => {
  return [
    { name: t("label.membre"), icon: <UserGroupIcon className="h-5 w-5 text-slate-500" />, path: "/hello" },
    { name: t("label.banner"), icon: <FlagIcon className="h-5 w-5 text-slate-500" />, path: "/banners" },
    { name: t("label.groupActivity"), icon: <CubeIcon className="h-5 w-5 text-slate-500" />, path: "/groups" },
    { name: t("label.reports"), icon: <ShieldExclamationIcon className="h-5 w-5 text-slate-500" />, path: "/reports" },
  ];
};

