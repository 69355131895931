import React, { useEffect, useState } from "react";
import * as sideMenus from "../utils/sideMenus";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import TableauGrid from "../components/TableauGrid";
import moment from "moment";
import { useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { authSelector } from "../features/authSlice";
import Controls from "../components/controls/Controls";
import Loading from "../components/Loading";
import DialogBox from "../components/DialogBox";
import { toast } from "react-toastify";

function ReportScreen(props) {
    const { t } = props;
    const axiosPrivate = useAxiosPrivate();
    let [isOpen, setIsOpen] = useState(false);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const location = useLocation();
    const auth = useSelector(authSelector);
    const navigate = useNavigate();
    const { id } = useParams(); // maka id
    const [selectedRow, setSelectedRow] = useState();
    const [allEmail, setAllEmail] = useState();
    const [isAfterJune, setIsAfterJune] = useState(false);
    const [hasActiveZap, setHasActiveZap] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [report, setReport] = useState();
    const columns = [
        {
            field: "id",
            headerName: "id",
            hide: true,
        },
        {
            field: "createdAt",
            headerName: "Date",
            type: "date",
            width: 150,
            renderCell: (cellValues) => {
                if (cellValues.row.createdAt) {
                    return moment(cellValues.row.createdAt).format("DD/MM/YYYY HH:mm");
                } else {
                    return null;
                }
            },
        },

        {
            field: "owner",
            headerName: 'Creator',
            width: 150,
            renderCell: (cellValues) => {
                if (cellValues.row.type) {
                    return cellValues.row.type == 'post' ? cellValues?.row?.post?.memberPost?.pseudo || ''
                        : cellValues.row.type == 'photo' ? cellValues?.row?.picture?.member?.pseudo || ''
                            : cellValues.row.type == 'video' ? cellValues?.row?.video?.member?.pseudo || ''
                                : cellValues.row.type == 'member' ? cellValues?.row?.member?.pseudo || '-'
                                    : '-'
                } else {
                    return null;
                }
            },
        },

        {
            field: "type",
            headerName: t("label.type"),
            renderCell: (cellValues) => {
                if (cellValues.row.type) {
                    return cellValues.row.type == 'post' ? <div className='text-green-500'>Post</div>
                        : cellValues.row.type == 'photo' ? <div className='text-blue-600'>Photo</div>
                            : cellValues.row.type == 'video' ? <div className='text-orange-500'>Video</div>
                                : cellValues.row.type == 'member' ? <div className='text-pink-600'>Member</div>
                                    : '-'
                } else {
                    return null;
                }
            },
        },
        {
            field: "fromid",
            headerName: 'From',
            width: 150,
            renderCell: (cellValues) => {
                if (cellValues.row.fromid) {
                    return cellValues.row.fromid.pseudo
                } else {
                    return null;
                }
            },
        },






        {
            field: "action",
            headerName: '',
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-primary dark:text-slate-100 hover:text-secondary"}
                        text={"View"}
                        onClick={(event) => {
                            const dataToShow = report.filter((a) => a.id === cellValues.row.id);
                            setSelectedRow(dataToShow[0]);
                            setIsOpen(true);
                        }}
                    />
                );
            },
        },
    ];

    function refreshPage() {
        window.location.reload(false);
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getAllReportData = async () => {
            try {
                const resp = await axiosPrivate.get(`/api/report/findAll`, {
                    signal: controller.signal,
                });

                //console.log("=rerere----------------------", resp)


                if (resp.data) {
                    const formatReport = resp.data.map(async element => {
                        let responseUser;
                        if (element.memberId) {
                            responseUser = await axiosPrivate.get(`/api/auth/getOneUserById/${element.memberId}`)
                            return {
                                ...element,
                                member: responseUser.data
                            }
                        } else {
                            return element
                        }

                    });

                    Promise.all(formatReport)
                        .then((formattedData) => {
                            // set state with formatted data
                            console.log(formattedData);
                            isMounted && setReport(formattedData);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }

            } catch (error) {

                if (error.response.status != 403) {
                    navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };

        getAllReportData()
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    const deletePost = async (id) => {
        try {
            const respAfterDelete = await axiosPrivate.delete(`/apiv1/bo/removePost/${id}`);
            //setReport(respAfterDelete.data);
            refreshPage()
            setIsOpen(false)
        } catch (error) {
            console.log("erer", error)
        }
    }

    const deletePhoto = async (id) => {
        try {
            const respAfterDelete = await axiosPrivate.delete(`/apiv1/bo/removePhoto/${id}`);
            //setReport(respAfterDelete.data);
            refreshPage()
            setIsOpen(false)
        } catch (error) {
            console.log("erer", error)
        }
    }

    const deleteVideo = async (id) => {
        try {
            const respAfterDelete = await axiosPrivate.delete(`/apiv1/bo/removeVideo/${id}`);
            //setReport(respAfterDelete.data);
            refreshPage()
            setIsOpen(false)
        } catch (error) {
            console.log("erer", error)
        }
    }

    //delete account
    const handleDeleteAccount = async (id) => {
        try {
            const respAfterDeleteMember = await axiosPrivate.delete(`/apiv1/bo/deleteAccount/${id}`);
            setIsOpen(false)
            setIsOpenConfirm(false)
            refreshPage()
            toast.success("Member account deleted")
        } catch (error) {
            console.log("err", error)
        }
        // setIsLoading(true)
        /*console.log(id)
        toast.success("Member account deleted")*/
    }

    const MemberWhoReport = ({ owner, type, fromid, dateCreation }) => (
        <div className='flex flex-col '>

            {type !== "Profile" ?
                <div className='bg-red-100 flex items-center justify-center rounded-sm font-semibold p-2 mb-2'>
                    A {type} published by {owner.pseudo}  has been reported by  {fromid?.pseudo} on {moment(dateCreation).format("DD/MM/YYYY")}
                </div> :
                <div className='bg-red-100 flex items-center justify-center rounded-sm font-semibold p-2 mb-2'>
                    {owner.pseudo}'s profile  has been reported by  {fromid?.pseudo}  on {moment(dateCreation).format("DD/MM/YYYY")}
                </div>
            }


            <div className='flex-col space-y-3'>
                <div className='font-light text-xs'>Details {type}</div>
                <div className='flex items-center justify-center space-x-3'>
                    <div>
                        <img className='object-contain h-20 rounded-full' src={owner?.profilepic || "https://enappd.com/static/images/avatar.png"} />
                    </div>
                    <div className=' flex flex-1 flex-col text-sm'>
                        <div>
                            <span className='font-light'> username: </span> {owner.pseudo}
                        </div>
                        <div>
                            <span className='font-light'> Last name: </span>
                            {owner.lastName}
                        </div>
                        <div>
                            <span className='font-light'>  First name: </span>
                            {owner.firstName}
                        </div>

                    </div>
                </div>

            </div>

        </div>
    )


    return (
        <>
            <TopBar menus={sideMenus.menusAdmin(t)} />

            <div className="flex flex-row flex-grow">
                <Sidebar menus={sideMenus.menusAdmin(t)} />

                <div className="flex flex-row flex-1 mt-2 md:ml-52 ">
                    <div className="flex flex-col w-full p-4 ">
                        <div className="flex-none h-auto">
                            <h1 className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.reports`)}</h1>
                            <hr />
                        </div>

                        <div>
                            <div className="h-[76vh] bg-slate-50">
                                {report && report ? <TableauGrid column={columns} dataRows={report && report} /> : <Loading />}
                            </div>
                            <DialogBox
                                isOpen={isOpenConfirm}
                                setIsOpen={setIsOpenConfirm}
                                title={'Are you sure ?'}
                                customClassName={"md:w-1/2 w-full min-h-[10px]"}
                            >
                                <div className='flex flex-col items-center justify-center space-y-4 text-sm'>
                                    <div>
                                        If you delete <span className='text-green-600 text-lg font-bold'> {selectedRow?.member?.pseudo} </span> account this membre he/she will no have access to the app anymore</div>
                                    <div>
                                        <span className='text-red-500 font-semibold text-lg'>This action is not reversible</span>

                                    </div>

                                    <div className='flex justify-center w-full space-x-3'>
                                        <Controls.Button color={'bg-slate-300 text-black'} text="Cancel" onClick={() => setIsOpenConfirm(false)} />
                                        <Controls.Button color={'bg-red-500 text-white'} text="Continue" onClick={() => handleDeleteAccount(selectedRow.member.id)} />
                                    </div>

                                </div>

                            </DialogBox>
                            <DialogBox
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                title={selectedRow && selectedRow.type}
                                customClassName={"md:w-1/2 w-full min-h-[300px]"}
                            >

                                <div className="h-fit bg-slate-100 my-2 p-2">
                                    {selectedRow && selectedRow.postId ?
                                        <>
                                            <div className='flex flex-col gap-y-5'>

                                                <MemberWhoReport owner={selectedRow.post.memberPost} fromid={selectedRow.fromid} type="Post" dateCreation={selectedRow.createdAt} />
                                                <div>
                                                    {selectedRow.post.text}
                                                </div>

                                                {selectedRow.post.photoUri &&
                                                    <div className='flex justify-center h-80'>
                                                        <img className='object-contain h-80' src={selectedRow.post.photoUri} />
                                                    </div>
                                                }

                                                {selectedRow.post.videoUri &&
                                                    <div className='flex justify-center h-80'>
                                                        <video controls>
                                                            <source src={selectedRow.post.videoUri} type="video/mp4" />
                                                            <source src="movie.ogg" type="video/ogg" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                }

                                                <div>
                                                    Created : {moment(selectedRow.post.createdAt).format("DD/MM/YYYY")}
                                                </div>

                                                <div className='flex justify-center w-full space-x-3'>
                                                    <Controls.Button color={'bg-slate-300 text-black'} text="Cancel" onClick={() => setIsOpen(false)} />
                                                    <Controls.Button color={'bg-red-500 text-white'} text="Delete post" onClick={() => deletePost(selectedRow.post.id)} />
                                                </div>

                                            </div>
                                        </>
                                        : selectedRow && selectedRow.pictureId ?
                                            <>
                                                <div className='flex flex-col gap-y-5'>
                                                    <MemberWhoReport owner={selectedRow.picture.member} fromid={selectedRow.fromid} type="Photo" dateCreation={selectedRow.createdAt} />
                                                    <div className='flex justify-center h-80'>
                                                        <img className='object-contain h-80' src={selectedRow.picture.url} />
                                                    </div>

                                                    <div>
                                                        Created : {moment(selectedRow.picture.createdAt).format("DD/MM/YYYY")}
                                                    </div>

                                                    <div className='flex justify-center w-full space-x-3'>
                                                        <Controls.Button color={'bg-slate-300 text-black'} text="Cancel" onClick={() => setIsOpen(false)} />
                                                        <Controls.Button color={'bg-red-500 text-white'} text="Delete" onClick={() => deletePhoto(selectedRow.picture.id)} />
                                                    </div>

                                                </div>
                                            </>
                                            : selectedRow && selectedRow.videoId ?
                                                <>
                                                    <div>
                                                        <MemberWhoReport owner={selectedRow.video.member} fromid={selectedRow.fromid} type="Video" dateCreation={selectedRow.createdAt} />
                                                        <div className='flex justify-center h-80'>
                                                            <video controls>
                                                                <source src={selectedRow.video.url} type="video/mp4" />
                                                                <source src="movie.ogg" type="video/ogg" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        </div>
                                                        <div>
                                                            Created : {moment(selectedRow.video.createdAt).format("DD/MM/YYYY")}
                                                        </div>

                                                        <div className='flex justify-center w-full space-x-3'>
                                                            <Controls.Button color={'bg-slate-300 text-black'} text="Cancel" onClick={() => setIsOpen(false)} />
                                                            <Controls.Button color={'bg-red-500 text-white'} text="Delete" onClick={() => deleteVideo(selectedRow.video.id)} />
                                                        </div>

                                                    </div>
                                                </>
                                                : selectedRow && selectedRow.memberId ?
                                                    <>
                                                        <div>
                                                            <MemberWhoReport owner={selectedRow.member} fromid={selectedRow.fromid} type="Profile" dateCreation={selectedRow.createdAt} />
                                                            {/* 
                                                            <div className='flex flex-col h-80'>
                                                                <p> <span className='font-semibold'> {selectedRow.member.pseudo} </span> has been reported by <span className='font-semibold'> {selectedRow.fromid.pseudo} </span></p>

                                                                <div className='flex-col space-y-3'>
                                                                    <div>Details</div>
                                                                    <div className='flex items-center justify-center space-x-3'>
                                                                        <div>
                                                                            <img className='object-contain h-32 rounded-full' src={selectedRow?.member?.profilepic || "https://enappd.com/static/images/avatar.png"} />
                                                                        </div>
                                                                        <div className=' flex flex-1 flex-col text-xs'>
                                                                            <div>
                                                                                username:  {selectedRow.member.pseudo}
                                                                            </div>
                                                                            <div>
                                                                                Last Name:  {selectedRow.member.lastName}
                                                                            </div>
                                                                            <div>
                                                                                First Name: {selectedRow.member.firstName}
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div> */}
                                                            <div className='mt-2'>
                                                                Account created on : {moment(selectedRow.member.createdAt).format("DD/MM/YYYY")}
                                                            </div>

                                                            <div className='flex justify-center w-full space-x-3'>
                                                                <Controls.Button color={'bg-slate-300 text-black'} text="Cancel" onClick={() => setIsOpen(false)} />
                                                                <Controls.Button color={'bg-red-500 text-white'} text={`Delete ${selectedRow.member.pseudo}'s account`} onClick={() => { setIsOpenConfirm(!isOpenConfirm); setIsOpen(false) }} />
                                                            </div>

                                                        </div>
                                                    </>
                                                    : null
                                    }
                                </div>
                            </DialogBox>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportScreen;
